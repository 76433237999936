import { Typography } from "antd";
import { useCallback, useMemo, useState } from "react";
import { useCss } from "react-use";
import type { TableTabItem } from "../../../../../spreadsheet/domain";
import type {
  FetchedDestination,
  IDatasetLineageGraph,
  TabData,
} from "../../../domain";
import { getEdgeStyle, REACT_FLOW_BASE_CONFIGURATION } from "./domain";

import type { BuiltInNode } from "@xyflow/react";
import {
  Background,
  Controls,
  MiniMap,
  Panel,
  ReactFlow,
  useEdgesState,
  useNodesState,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { black, grey } from "../../../../../../utils/colorPalette";
import { useLayoutedElements } from "./hooks/useLayoutedElements";
import { ModelInfo } from "./ModelInfo";
import type { ModelNodeType } from "./ModelNode";

interface ILineageRendererProps {
  datasetLineageGraph: IDatasetLineageGraph;
  currentTab: TabData;
  tableTabItems: TableTabItem[];
  destination: FetchedDestination;
}

type CustomNode = BuiltInNode | ModelNodeType;

/* WARNING : Make sure to pretty much always memo/callback code in this component or ReactFlow may make an infinite render loop */
const LineageRenderer = ({
  datasetLineageGraph,
  currentTab: { dataset },
  tableTabItems,
  destination,
}: ILineageRendererProps) => {
  const parentIds = dataset.dependsOn.map((dep) => dep.child.id);
  const childIds = dataset.isChildOf.map((dep) => dep.parent.id);

  const { nodes: layoutedNodes, edges: layoutedEdges } = useLayoutedElements({
    tabItems: tableTabItems,
    current: tableTabItems.find((tti) => tti.dataset.id === dataset.id),
    parentIds,
    childIds,
  });
  const [selectedNode, setSelectedNode] = useState<CustomNode | undefined>(
    undefined
  );
  const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);

  const fitOnCurrentNode = useMemo(() => nodes.length > 50, [nodes]);

  const handleNodeClick = useCallback(
    (_, node: CustomNode) => {
      setSelectedNode(node);
      setEdges(
        edges.map((e) => ({
          ...e,
          style: e.id.includes(node.id) ? getEdgeStyle(true) : {},
        }))
      );
    },
    [edges, setEdges]
  );

  const handleUnselectNode = useCallback(() => {
    setSelectedNode(undefined);
    setNodes(nodes.map((n) => ({ ...n, selected: false })));
    setEdges(edges.map((e) => ({ ...e, style: getEdgeStyle(false) })));
  }, [edges, nodes, setEdges, setNodes]);

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      fitViewOptions={
        fitOnCurrentNode
          ? { maxZoom: 1, nodes: [{ id: dataset.id }] }
          : undefined
      }
      minZoom={0.3}
      onNodeClick={handleNodeClick}
      onPaneClick={handleUnselectNode}
      {...REACT_FLOW_BASE_CONFIGURATION}
    >
      <MiniMap style={{ backgroundColor: grey[6], borderRadius: "8px" }} />
      <Controls showInteractive={false} />
      <Background color={black} />
      <Panel position="top-left">
        {selectedNode && selectedNode.type === "model" ? (
          <ModelInfo
            tabItem={selectedNode.data.tabItem}
            onClose={handleUnselectNode}
          />
        ) : (
          <NoModelNodeSelected />
        )}
      </Panel>
    </ReactFlow>
  );
};

const NoModelNodeSelected = () => {
  const cs = useCss({
    background: "rgba(255, 255, 255, 0.7)",
    padding: "8px",
    borderRadius: "8px",
    backdropFilter: "blur(4px)",
  });

  return (
    <Typography.Text className={cs}>
      Click on a model to see more
    </Typography.Text>
  );
};

export default LineageRenderer;
