import { useCallback, useMemo } from "react";
import type { TableTabItem } from "../../../../../spreadsheet/domain";

import { CloseOutlined } from "@ant-design/icons";
import { Button, Flex, Tag, Tooltip, Typography } from "antd";
import { inject, observer } from "mobx-react";
import { compose } from "../../../../../../components/compose/WlyCompose";
import { SourceImageRenderer } from "../../../../../../components/sources/SourceImageRenderer";
import type { WorkbenchUIStoreProps } from "../../../../../../store/workbenchUIStore";
import { toDisplayErrors } from "../../DatasetPopover";
import "./ModelInfo.scss";

type ModelInfoProps = {
  tabItem: TableTabItem;
  onClose: () => void;
};

type Props = ModelInfoProps & WorkbenchUIStoreProps;

const Comp = ({ tabItem, onClose, workbenchUIStore }: Props) => {
  const { dataset } = tabItem;

  const isNotManagedByDbtCloud = useMemo(
    () => tabItem.managedBy !== "DBT_CLOUD",
    [tabItem.managedBy]
  );
  const isMaterialized = useMemo(
    () => ["view", "table"].includes(dataset.isPersistedAs),
    [dataset.isPersistedAs]
  );
  const hasErrors = useMemo(
    () => toDisplayErrors(tabItem).length > 0,
    [tabItem]
  );

  const openModelTab = useCallback(() => {
    workbenchUIStore.pushActiveObject({
      type: "dataset",
      value: tabItem.key,
    });
  }, [tabItem.key, workbenchUIStore]);

  return (
    <div className="model-info">
      <Flex vertical gap="middle">
        <Flex vertical gap="4px">
          <Flex align="center" gap="small">
            <SourceImageRenderer
              size={24}
              isModel={dataset.isModel}
              alt={dataset.name}
              img={dataset.source?.sourceMeta?.publicInfo?.logo}
            />

            <Tooltip title={dataset.name}>
              <Typography.Text strong ellipsis className="model-info-title">
                {dataset.name}
              </Typography.Text>
            </Tooltip>

            <Button
              size="small"
              type="text"
              icon={<CloseOutlined />}
              onClick={onClose}
              style={{ flexShrink: 0 }}
            />
          </Flex>

          <Flex gap="2px" wrap="wrap">
            {isMaterialized && <Tag color="purple">Materialized</Tag>}
            {hasErrors && <Tag color="error">Has errors</Tag>}
          </Flex>
        </Flex>

        {dataset.description ?? (
          <Typography.Text italic>Empty description</Typography.Text>
        )}

        <Flex gap="small" justify="flex-end" align="center">
          <Button size="small" onClick={openModelTab}>
            Open
          </Button>
        </Flex>
      </Flex>
    </div>
  );
};

export const ModelInfo = compose<Props, ModelInfoProps>()(
  inject("workbenchUIStore")(observer(Comp))
);
