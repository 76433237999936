import { Handle, Position, type Node, type NodeProps } from "@xyflow/react";
import { Button, Flex, Tooltip, Typography } from "antd";
import type { ModelNodeData } from "./domain";
import { NODE_HEIGHT, NODE_WIDTH } from "./domain";

import { ZoomInOutlined } from "@ant-design/icons";
import classNames from "classnames";
import { inject, observer } from "mobx-react";
import { useCallback } from "react";
import { compose } from "../../../../../../components/compose/WlyCompose";
import { SourceImageRenderer } from "../../../../../../components/sources/SourceImageRenderer";
import type { WorkbenchUIStoreProps } from "../../../../../../store/workbenchUIStore";
import "./ModelNode.scss";

export type ModelNodeType = Node<ModelNodeData, "model">;

type Props = NodeProps<ModelNodeType> & WorkbenchUIStoreProps;

/* WARNING : Make sure to pretty much always memo/callback code in this component or ReactFlow may make an infinite render loop */
const Comp = ({
  data: { tabItem, isCurrent },
  sourcePosition,
  targetPosition,
  isConnectable,
  selected,
  workbenchUIStore,
}: Props) => {
  const { dataset } = tabItem;

  const openModelTab = useCallback(() => {
    workbenchUIStore.pushActiveObject({
      type: "dataset",
      value: tabItem.key,
    });
  }, [tabItem.key, workbenchUIStore]);

  return (
    <>
      <Handle
        type="target"
        position={targetPosition ?? Position.Left}
        isConnectable={isConnectable}
      />
      <div
        className={classNames("model-node", { selected, current: isCurrent })}
        style={{
          width: NODE_WIDTH,
          height: NODE_HEIGHT,
        }}
      >
        <Flex align="center" gap="small">
          <SourceImageRenderer
            size={24}
            isModel={dataset.isModel}
            alt={dataset.name}
            img={dataset.source?.sourceMeta?.publicInfo?.logo}
          />
          <Typography.Text strong ellipsis className="model-node-name">
            {dataset.name}
          </Typography.Text>
        </Flex>
        <Flex align="center" justify="flex-end" className="model-node-actions">
          <Tooltip title="Open in a new tab">
            <Button icon={<ZoomInOutlined />} onClick={openModelTab} />
          </Tooltip>
        </Flex>
      </div>
      <Handle
        type="source"
        position={sourcePosition ?? Position.Right}
        isConnectable={isConnectable}
      />
    </>
  );
};

export const ModelNode = compose<Props, NodeProps<ModelNodeType>>()(
  inject("workbenchUIStore")(observer(Comp))
);
