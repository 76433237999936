import * as React from "react";

export interface ILinkProps {
  target?: string;
  id: string;
  href?: string;
}

export function Link(props: React.PropsWithChildren<ILinkProps>) {
  const { target, children, id, href } = props;

  return (
    <a href={href} id={id} target={target}>
      {children}
    </a>
  );
}
