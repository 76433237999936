import { Callout } from "./components/Callout";
import { Collapse } from "./components/Collapse";
import { Divider } from "./components/Divider";
import { FloatingMenu } from "./components/FloatingMenu";
import { FloatingToc } from "./components/FloatingTOC";
import { Group } from "./components/Group";
import { Heading } from "./components/Heading";
import { Link } from "./components/Link";
import { List } from "./components/List";
import { Logo } from "./components/Logo";
import { Paragraph } from "./components/Paragraph";
import { RCA } from "./components/RCA";
import { ScrollTo } from "./components/ScrollTo";
import { Spacing } from "./components/Spacing";
import { Tag } from "./components/Tag";
import { TextFormatting } from "./components/TextFormatting";
import { Toggle } from "./components/Toggle";
import { callout } from "./schema/callout.markdoc";
import { collapse } from "./schema/collapse.markdoc";
import { floatingMenu } from "./schema/floating-menu.markdoc";
import { floatingToc } from "./schema/floating-toc.markdoc";
import { group } from "./schema/group.markdoc";
import { heading } from "./schema/heading.markdoc";
import { hr } from "./schema/hr.markdoc";
import { link } from "./schema/link.markdoc";
import { list } from "./schema/list.makdoc";
import { logo } from "./schema/logo.markdoc";
import { paragraph } from "./schema/paragraph.markdoc";
import { rca } from "./schema/rca.markdoc";
import { scrollTo } from "./schema/scrollto.markdoc";
import { spacing } from "./schema/spacing.markdoc";
import { tag } from "./schema/tag.markdoc";
import { textFormatting } from "./schema/text-formatting";
import { toggle } from "./schema/toggle.markdoc";

export const nodesConfig = {
  nodes: {
    heading,
    paragraph,
    list,
    hr,
  },
  tags: {
    toggle,
    rca,
    "text-formatting": textFormatting,
    group: group,
    tag,
    logo,
    "floating-toc": floatingToc,
    "floating-menu": floatingMenu,
    spacing: spacing,
    callout,
    collapse,
    scrollTo,
    link,
  },
};

export const additionalComponents = {
  components: {
    Heading: Heading,
    Paragraph: Paragraph,
    List: List,
    Divider: Divider,
    RCA: RCA,
    Toggle: Toggle,
    TextFormatting: TextFormatting,
    Group: Group,
    Tag: Tag,
    Logo,
    FloatingToc,
    FloatingMenu,
    Spacing,
    Callout,
    Collapse,
    ScrollTo,
    Link,
  },
};
