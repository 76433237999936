import {
  Button,
  Checkbox,
  Divider,
  Dropdown,
  Input,
  InputNumber,
  Select,
  Space,
  Typography,
} from "antd";
import useToken from "antd/es/theme/useToken";
import _ from "lodash";
import type { Moment } from "moment";
import moment from "moment";
import { useEffect, useState } from "react";
import DatePicker from "../../../../../components/datepicker/DatePicker";
import type { DATE_RANGE_PRESET, SimpleDateRange } from "../domain";
import { PRESETS } from "../domain";
import {
  AdvancedDatePicker,
  ComputeAdvancedDatePickerPeriod,
  isPeriodRangeValid,
} from "./AdvancedDateRangePicker";
import type { AdvancedDatePickerPeriod } from "./AdvancedDateRangePickerPanel";
import "./WlyDatePicker.scss";

const { RangePicker } = DatePicker;

interface IWlyDatePickerProps {
  name?: string;
  block?: boolean;
  displayNameAs?: "inline" | "placeholder";
  disabled?: boolean;
  size?: "small" | "middle" | "large";
  allowNullValue?: boolean;
  value?: IWlyDatePickerInputValue;
  onChange?: (value: IWlyDatePickerValue) => void;
}

type IWlyDatePickerType =
  | "none"
  | "preset"
  | "previous"
  | "next"
  | "current"
  | "on"
  | "between"
  | "advanced";

type IWlyDatePickerCount = number;

type IWlyDatePickerPeriod =
  | "hour"
  | "day"
  | "week"
  | "quarter"
  | "month"
  | "year";

export type IWlyDatePickerInputValue =
  | IWlyDatePickerValue
  | DATE_RANGE_PRESET
  | SimpleDateRange
  | undefined;

export type IWlyDatePickerValue =
  | {
      type: Extract<IWlyDatePickerType, "preset">;
      preset: DATE_RANGE_PRESET;
    }
  | {
      type: Extract<IWlyDatePickerType, "next" | "previous">;
      count: IWlyDatePickerCount;
      period: IWlyDatePickerPeriod;
      includeCurrentPeriod: boolean;
    }
  | {
      type: Extract<IWlyDatePickerType, "current">;
      period: IWlyDatePickerPeriod;
    }
  | {
      type: Extract<IWlyDatePickerType, "on">;
      on: Moment;
    }
  | {
      type: Extract<IWlyDatePickerType, "between">;
      between: SimpleDateRange;
    }
  | {
      type: Extract<IWlyDatePickerType, "advanced">;
      startPeriod: AdvancedDatePickerPeriod;
      endPeriod: AdvancedDatePickerPeriod;
    };

type IWlyDatePickerInitialData = {
  type: IWlyDatePickerType;
  preset: DATE_RANGE_PRESET;
  count: IWlyDatePickerCount;
  period: IWlyDatePickerPeriod;
  includeCurrentPeriod: boolean;
  on: Moment;
  between: SimpleDateRange;
  startPeriod: AdvancedDatePickerPeriod;
  endPeriod: AdvancedDatePickerPeriod;
};

const MOMENT_DAY_FORMAT = "MMM D, YYYY";
const MOMENT_HOUR_FORMAT = "HH:mm:ss";
const MOMENT_DAY_HOUR_FORMAT = "MMM D, YYYY HH:mm:ss";

export const convertWlyDatePickerInputValueToValue = (
  iv: IWlyDatePickerInputValue,
  allowNull?: boolean
): IWlyDatePickerValue => {
  if (typeof iv === "string" && PRESETS.flatMap((p) => p.key).includes(iv)) {
    return {
      type: "preset",
      preset: iv,
    };
  } else if (Array.isArray(iv)) {
    return {
      type: "between",
      between: iv,
    };
  } else if (iv) {
    return iv as IWlyDatePickerValue;
  } else {
    return {
      type: "preset",
      preset: "LAST_7_DAYS",
    };
  }
};

export const convertWlyDatePickerValueToString = (
  value: IWlyDatePickerInputValue
): string => {
  const convertedValue = convertWlyDatePickerInputValueToValue(value);
  return JSON.stringify(convertedValue);
};

export const convertStringToWlyDatePickerValue = (
  s: string
): IWlyDatePickerValue => {
  try {
    // support for legacy preset format
    if (PRESETS.flatMap((p) => p.key).includes(s as DATE_RANGE_PRESET)) {
      return {
        type: "preset",
        preset: s as DATE_RANGE_PRESET,
      };
    }
    // support for legacy between format
    const [from, to] = s.split(" to ");
    if (moment(from).isValid() && moment(to).isValid()) {
      return {
        type: "between",
        between: [moment(from), moment(to)],
      };
    }
    let value: IWlyDatePickerValue = JSON.parse(s);
    if (value.type === "between") {
      value.between = value.between.map((b) => moment(b)) as SimpleDateRange;
    }
    if (value.type === "on") {
      value.on = moment(value.on);
    }
    if (value.type === "advanced") {
      if (value.startPeriod.fixedDate) {
        value.startPeriod.fixedDate = moment(value.startPeriod.fixedDate);
      }
      if (value.endPeriod.fixedDate) {
        value.endPeriod.fixedDate = moment(value.endPeriod.fixedDate);
      }
    }
    return value;
  } catch (error) {
    return {
      type: "preset",
      preset: "LAST_7_DAYS",
    };
  }
};

export const convertWlyDatePickerValueToMoment = (
  inputValue: IWlyDatePickerInputValue
): SimpleDateRange | undefined => {
  const value = convertWlyDatePickerInputValueToValue(inputValue);
  if (value.type === "preset") {
    if (value.preset === "ALL_TIME") {
      return undefined;
    } else {
      const [start, end] = PRESETS.find(
        (preset) => preset.key === value.preset
      ).values;
      return [start, end];
    }
  }
  if (value.type === "previous") {
    const momentPeriod = value.period === "week" ? "isoWeek" : value.period;
    const start = moment()
      .startOf(momentPeriod)
      .subtract(value.count, value.period)
      .startOf(momentPeriod);
    const end = moment()
      .startOf(momentPeriod)
      .subtract(!value.includeCurrentPeriod ? 1 : 0, value.period)
      .endOf(momentPeriod);
    return [start, end];
  } else if (value.type === "next") {
    const momentPeriod = value.period === "week" ? "isoWeek" : value.period;
    const start = moment()
      .startOf(momentPeriod)
      .add(value.includeCurrentPeriod ? 0 : 1, value.period)
      .startOf(momentPeriod);
    const end = moment()
      .startOf(momentPeriod)
      .add(value.count, value.period)
      .endOf(momentPeriod);
    return [start, end];
  } else if (value.type === "current") {
    const momentPeriod = value.period === "week" ? "isoWeek" : value.period;
    return [moment().startOf(momentPeriod), moment().endOf(momentPeriod)];
  } else if (value.type === "between") {
    return [
      moment(value.between[0]).startOf("day"),
      moment(value.between[1]).endOf("day"),
    ];
  } else if (value.type === "on") {
    return [moment(value.on).startOf("day"), moment(value.on).endOf("day")];
  } else if (value.type === "advanced") {
    if (value.startPeriod && value.endPeriod) {
      const start = ComputeAdvancedDatePickerPeriod(value.startPeriod);
      const end = ComputeAdvancedDatePickerPeriod(value.endPeriod);
      return [start, end];
    }
  }
  return undefined;
};

export function WlyDatePicker(props: IWlyDatePickerProps) {
  const {
    onChange,
    disabled,
    size,
    block,
    name,
    value,
    allowNullValue,
    displayNameAs,
  } = props;

  const initialValue = convertWlyDatePickerInputValueToValue(
    value,
    allowNullValue
  );
  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
  const [state, setState] = useState<IWlyDatePickerValue>(initialValue);
  const [, token] = useToken();

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  useEffect(() => {
    if (!isMenuVisible) {
      setState(initialValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuVisible]);

  const initialData: IWlyDatePickerInitialData = {
    type: "advanced",
    preset: "LAST_7_DAYS",
    count: 30,
    period: "day",
    includeCurrentPeriod: false,
    on: moment(),
    between: [moment().subtract(7, "day"), moment()],
    startPeriod: {
      startOn: "start_day",
      operator: "plus",
      difference: 0,
      fixedDate: moment().startOf("day"),
      period: "day",
      roundTo: "nothing",
    },
    endPeriod: {
      startOn: "end_day",
      operator: "plus",
      difference: 0,
      fixedDate: moment().startOf("day"),
      period: "day",
      roundTo: "nothing",
    },
  };

  const getIncludeLabel = (period: IWlyDatePickerPeriod) => {
    switch (period) {
      case "hour":
        return "this hour";
      case "day":
        return "today";
      case "week":
        return "this week";
      case "quarter":
        return "this quarter";
      case "month":
        return "this month";
      case "year":
        return "this year";
      default:
        return null;
    }
  };

  const getButtonLabel = () => {
    if (allowNullValue && !value) {
      // in case we allow null in the component we don't want to display a dummy value that is not used
      // in the above api call so we don't display date here
      return "";
    }
    if (initialValue.type === "preset") {
      return PRESETS.find((p) => p.key === initialValue.preset)?.label;
    } else if (
      initialValue.type === "previous" ||
      initialValue.type === "next"
    ) {
      const displayOperator =
        initialValue.type === "next" ? "Next" : "Previous";
      if (initialValue.count && Math.abs(initialValue.count) > 1) {
        return `${displayOperator} ${initialValue.count} ${getPeriodLabel(
          initialValue.period,
          initialValue.count
        )}`;
      } else {
        return `${displayOperator} ${getPeriodLabel(initialValue.period)}`;
      }
    } else if (initialValue.type === "current") {
      return `Current ${initialValue.period}`;
    } else if (initialValue.type === "on") {
      return `On ${moment(initialValue.on)?.format(MOMENT_DAY_FORMAT)}`;
    } else if (initialValue.type === "between") {
      return `${moment(initialValue.between[0])?.format(
        MOMENT_DAY_FORMAT
      )} - ${moment(initialValue.between[1])?.format(MOMENT_DAY_FORMAT)}`;
    } else if (initialValue.type === "advanced") {
      const start = ComputeAdvancedDatePickerPeriod(initialValue.startPeriod);
      const end = ComputeAdvancedDatePickerPeriod(initialValue.endPeriod);
      let format = MOMENT_DAY_HOUR_FORMAT;
      if (
        start &&
        end &&
        moment(start).format(MOMENT_HOUR_FORMAT) === "00:00:00" &&
        moment(end).format(MOMENT_HOUR_FORMAT) === "23:59:59"
      ) {
        format = MOMENT_DAY_FORMAT;
      }
      return `${moment(start)?.format(format)} - ${moment(end)?.format(
        format
      )}`;
    } else {
      return "Unknown date";
    }
  };

  const getPeriodLabel = (period: IWlyDatePickerPeriod, count?: number) => {
    if (Math.abs(count || 0) > 1) {
      return `${period}s`;
    } else {
      return period;
    }
  };

  const renderDateForm = () => {
    return (
      <Space direction="vertical" style={{ width: "100%" }}>
        <Input.Group compact>
          <Select<IWlyDatePickerType>
            value={state.type}
            onChange={(v: IWlyDatePickerType) => {
              if (v === "previous" || v === "next") {
                setState({
                  type: v,
                  count: initialData.count,
                  period: initialData.period,
                  includeCurrentPeriod: initialData.includeCurrentPeriod,
                });
              } else if (v === "current") {
                setState({
                  type: "current",
                  period: initialData.period,
                });
              } else if (v === "between") {
                setState({
                  type: "between",
                  between: initialData.between,
                });
              } else if (v === "on") {
                setState({
                  type: "on",
                  on: initialData.on,
                });
              } else if (v === "advanced") {
                setState({
                  type: "advanced",
                  startPeriod: initialData.startPeriod,
                  endPeriod: initialData.endPeriod,
                });
              } else if (v === "preset") {
                setState({
                  type: "preset",
                  preset: initialData.preset,
                });
              }
            }}
            style={{
              width: ["on", "between", "advanced", ""].includes(state.type)
                ? 288
                : 110,
            }}
            options={[
              {
                value: "preset",
                label: "Preset",
              },
              {
                value: "previous",
                label: "Previous",
              },
              {
                value: "next",
                label: "Next",
              },
              {
                value: "current",
                label: "Current",
              },
              {
                value: "on",
                label: "On",
              },
              {
                value: "between",
                label: "Between",
              },
              {
                value: "advanced",
                label: "Advanced",
              },
            ]}
          />
          {state.type === "preset" ? (
            <Select<DATE_RANGE_PRESET>
              style={{
                width: 180,
              }}
              value={state.preset}
              onChange={(preset) =>
                setState({
                  ...state,
                  preset,
                })
              }
              options={PRESETS.map((p) => {
                return {
                  label: p.label,
                  value: p.key,
                };
              })}
            />
          ) : null}
          {(state.type === "previous" || state.type === "next") && (
            <InputNumber<IWlyDatePickerCount>
              style={{ width: 80 }}
              value={state.count}
              min={1}
              precision={0}
              onChange={(count: IWlyDatePickerCount) =>
                setState({
                  ...state,
                  count,
                })
              }
            />
          )}
          {(state.type === "previous" ||
            state.type === "next" ||
            state.type === "current") && (
            <Select<IWlyDatePickerPeriod>
              value={state.period}
              onChange={(period) =>
                setState({
                  ...state,
                  period,
                })
              }
              style={{ width: state.type === "current" ? 180 : 100 }}
              options={[
                {
                  value: "hour",
                  label: getPeriodLabel(
                    "hour",
                    state.type === "current" ? 0 : state.count
                  ),
                },
                {
                  value: "day",
                  label: getPeriodLabel(
                    "day",
                    state.type === "current" ? 0 : state.count
                  ),
                },
                {
                  value: "week",
                  label: getPeriodLabel(
                    "week",
                    state.type === "current" ? 0 : state.count
                  ),
                },
                {
                  value: "month",
                  label: getPeriodLabel(
                    "month",
                    state.type === "current" ? 0 : state.count
                  ),
                },
                {
                  value: "quarter",
                  label: getPeriodLabel(
                    "quarter",
                    state.type === "current" ? 0 : state.count
                  ),
                },
                {
                  value: "year",
                  label: getPeriodLabel(
                    "year",
                    state.type === "current" ? 0 : state.count
                  ),
                },
              ]}
            />
          )}
        </Input.Group>
        {state.type === "on" && (
          <>
            <DatePicker
              style={{
                width: 288,
              }}
              value={state.on}
              onChange={(value) => {
                setState({
                  ...state,
                  on: value,
                });
              }}
            />
          </>
        )}
        {state.type === "between" && (
          <RangePicker
            style={{
              width: 288,
            }}
            value={state.between}
            onChange={(values) =>
              setState({
                ...state,
                between: [values[0].startOf("day"), values[1].endOf("day")],
              })
            }
          />
        )}
        {state.type === "advanced" && (
          <div style={{ width: 560 }}>
            <AdvancedDatePicker
              value={{
                startPeriod: state.startPeriod,
                endPeriod: state.endPeriod,
              }}
              onChange={(periods) => {
                setState({
                  ...state,
                  startPeriod: periods.startPeriod,
                  endPeriod: periods.endPeriod,
                });
              }}
            />
          </div>
        )}
        {(state.type === "previous" || state.type === "next") && (
          <Checkbox
            onChange={(e) =>
              setState({
                ...state,
                includeCurrentPeriod: e.target.checked,
              })
            }
            checked={state.includeCurrentPeriod}
          >
            Include {getIncludeLabel(state.period)}
          </Checkbox>
        )}
      </Space>
    );
  };

  const renderDateRange = () => {
    const dateRange = convertWlyDatePickerValueToMoment(state);
    if (dateRange) {
      let format = MOMENT_DAY_FORMAT;
      if (
        (state.type === "previous" ||
          state.type === "next" ||
          state.type === "current") &&
        state.period === "hour"
      ) {
        format = MOMENT_DAY_HOUR_FORMAT;
      }
      return (
        <Typography.Text type="secondary">
          {dateRange[0]?.format(format)} - {dateRange[1]?.format(format)}
        </Typography.Text>
      );
    } else {
      return null;
    }
  };

  return (
    <Dropdown
      open={isMenuVisible}
      onOpenChange={setIsMenuVisible}
      disabled={disabled}
      trigger={["click"]}
      placement="topLeft"
      dropdownRender={() => {
        return (
          <div
            className="wlydatepicker-custom-menu"
            style={{
              ...contentStyle,
            }}
          >
            <div
              style={{
                width: state.type === "advanced" ? 560 : 290,
                margin: "4px 12px",
              }}
            >
              <div>{renderDateForm()}</div>
              {["preset", "previous", "next", "current"].includes(
                state.type
              ) && (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    paddingTop: 12,
                  }}
                >
                  {renderDateRange()}
                </div>
              )}
            </div>
            <Divider style={{ margin: "4px 0" }} />
            <div style={{ margin: "12px 12px" }}>
              <div
                style={{
                  display: "flex",
                  flexWrap: "nowrap",
                  flexDirection: "row",
                  minHeight: 46,
                }}
              >
                <div
                  style={{
                    flex: "1 1 auto",
                  }}
                >
                  <Button
                    onClick={() => {
                      setIsMenuVisible(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <div
                  style={{
                    flex: "0 0 auto",
                  }}
                >
                  <Button
                    type="primary"
                    disabled={
                      state.type === "advanced" &&
                      !isPeriodRangeValid(state.startPeriod, state.endPeriod)
                    }
                    onClick={() => {
                      setIsMenuVisible(false);
                      if (onChange) {
                        onChange(state);
                      }
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </div>
          </div>
        );
      }}
    >
      <div
        style={{
          display: "inline-block",
          width: block ? "100%" : "initial",
        }}
      >
        <Button
          size={size}
          disabled={disabled}
          onClick={() => setIsMenuVisible(!isMenuVisible)}
          block={block}
          style={{ textAlign: block ? "left" : undefined, maxWidth: "180px" }}
        >
          <Typography.Text
            ellipsis={{ tooltip: getButtonLabel() }}
            style={{ width: "100%" }}
          >
            {name && (!displayNameAs || displayNameAs === "inline") ? (
              <>
                <Typography.Text strong>{_.capitalize(name)}</Typography.Text>
                {": "}
              </>
            ) : null}
            {name && displayNameAs === "placeholder" && !value ? (
              <Typography.Text type="secondary">
                {_.capitalize(name)}
              </Typography.Text>
            ) : null}
            {disabled ? "No date selected" : getButtonLabel()}
          </Typography.Text>
        </Button>
      </div>
    </Dropdown>
  );
}
