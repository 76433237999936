import { EllipsisOutlined } from "@ant-design/icons";
import type { DropdownProps } from "antd";
import { Button, Dropdown } from "antd";
import { inject, observer } from "mobx-react";
import { compose } from "../../../../components/compose/WlyCompose";
import type { WorkbenchUIStoreProps } from "../../../../store/workbenchUIStore";
import type { TableTabItem } from "../../../spreadsheet/domain";

type ModelItemActionsMenuProps = {
  tabItem: TableTabItem;
  onCreateDataset?: () => void;
  onDeleteModel?: () => void;
  getPopupContainer?: DropdownProps["getPopupContainer"];
};

type Props = ModelItemActionsMenuProps & WorkbenchUIStoreProps;

const Comp = ({
  tabItem,
  onCreateDataset,
  onDeleteModel,
  getPopupContainer,
  workbenchUIStore,
}: Props) => {
  const { dataset, baseDatasetId, label } = tabItem;

  return (
    <Dropdown
      menu={{
        items: [
          {
            key: "edit",
            label: "Rename",
            onClick: (e) => {
              e.domEvent.stopPropagation();
              workbenchUIStore.setDatasetEditionOpen({
                id: baseDatasetId,
                name: label,
              });
            },
          },
          ...(onCreateDataset && dataset.type === "SQL"
            ? [
                {
                  key: "duplicate",
                  label: "Duplicate",
                  onClick: (e) => {
                    e.domEvent.stopPropagation();
                    onCreateDataset();
                  },
                },
              ]
            : []),
          ...(onDeleteModel
            ? [
                {
                  key: "remove",
                  danger: true,
                  label: "Delete",
                  onClick: (e) => {
                    e.domEvent.stopPropagation();
                    onDeleteModel();
                  },
                },
              ]
            : []),
        ],
      }}
      trigger={["click"]}
      placement={"bottomRight"}
      arrow={true}
      getPopupContainer={getPopupContainer}
    >
      <Button
        type="text"
        size="small"
        shape="circle"
        onClick={(e) => e.stopPropagation()}
        icon={<EllipsisOutlined rotate={90} />}
      />
    </Dropdown>
  );
};

export const ModelItemActionsMenu = compose<Props, ModelItemActionsMenuProps>()(
  inject("workbenchUIStore")(observer(Comp))
);
