import dagre from "@dagrejs/dagre";
import type { DiagramEngine } from "@projectstorm/react-diagrams";
import {
  DefaultLabelFactory,
  PortModelAlignment,
} from "@projectstorm/react-diagrams";
import type { Edge, Node, ReactFlowProps } from "@xyflow/react";
import { Position } from "@xyflow/react";
import { uniqBy } from "lodash";
import type { CSSProperties } from "react";
import { blue, grey } from "../../../../../../utils/colorPalette";
import type { TableTabItem } from "../../../../../spreadsheet/domain";
import { EditableLabelFactory } from "../flow/nodes/label/EditableLabelFactory";
import { FlowLinkFactory } from "../flow/nodes/link/FlowLinkFactory";
import { SimplePortFactory } from "../flow/nodes/port/SimplePortFactory";
import { SimplePortModel } from "../flow/nodes/port/SimplePortModel";
import { DatasetNodeFactory } from "./dataset-node/DatasetNodeFactory";
import { ExplorationNodeFactory } from "./exploration-node/ExplorationNodeFactory";
import { ModelNode } from "./ModelNode";

export const NODE_WIDTH = 256;
export const NODE_HEIGHT = 44;

export enum Direction {
  LR = "LR",
  TB = "TB",
}
export enum GraphType {
  UPSTREAM = "UPSTREAM",
  BASE = "BASE",
  DOWNSTREAM = "DOWNSTREAM",
}
export type GraphWithInfos =
  | Record<PropertyKey, { tabItem: TableTabItem; relatives: GraphWithInfos }>
  | Record<PropertyKey, never>;
export type ModelNodeData = { tabItem: TableTabItem; isCurrent: boolean };

export const REACT_FLOW_BASE_CONFIGURATION: ReactFlowProps = {
  fitView: true,
  style: { backgroundColor: grey[3] },
  proOptions: { hideAttribution: true },
  // connectionLineType: ConnectionLineType.SmoothStep,
  nodeTypes: {
    model: ModelNode,
  },
  nodesDraggable: false,
  nodesConnectable: false,
  edgesReconnectable: false,
  edgesFocusable: false,
  defaultEdgeOptions: {
    deletable: false,
    focusable: false,
  },
};

export const getEdgeStyle = (highlight: boolean = false): CSSProperties => ({
  stroke: highlight ? blue[6] : grey[5],
  strokeWidth: highlight ? 4 : 1,
  pointerEvents: "none",
});

const getRelativeIds = (tabItem: TableTabItem, graphType: GraphType) => {
  switch (graphType) {
    case GraphType.UPSTREAM:
      return tabItem.dataset.dependsOn.map((dep) => dep.child.id);
    case GraphType.DOWNSTREAM:
      return tabItem.dataset.isChildOf.map((dep) => dep.parent.id);
    case GraphType.BASE:
      return [];
  }
};
export const getGraphWithInfos = (
  tabItems: TableTabItem[],
  ids: string[],
  graphType: GraphType
): GraphWithInfos => {
  return ids.reduce<GraphWithInfos>((graph, id): GraphWithInfos => {
    const tabItem = tabItems.find(({ dataset }) => dataset.id === id);
    const relativeIds = tabItem ? getRelativeIds(tabItem, graphType) : [];

    return tabItem
      ? {
          ...graph,
          [id]: {
            tabItem,
            relatives: getGraphWithInfos(tabItems, relativeIds, graphType),
          },
        }
      : graph;
  }, {});
};

const generateNode = (
  key: string,
  tabItem: TableTabItem,
  isCurrent: boolean
): Node<ModelNodeData> => ({
  id: key,
  position: { x: 0, y: 0 },
  type: "model",
  data: { tabItem, isCurrent },
});

export const generateInitialNodes = (
  upstreamGraph: GraphWithInfos,
  baseGraph: GraphWithInfos,
  downstreamGraph: GraphWithInfos
): Node[] => {
  const getNodesFromGraph = (
    graph: GraphWithInfos,
    isCurrent: boolean = false
  ): Node[] => {
    return Object.keys(graph).reduce((nodes, key) => {
      const { tabItem, relatives } = graph[key];

      return [
        ...nodes,
        generateNode(key, tabItem, isCurrent),
        ...getNodesFromGraph(relatives),
      ];
    }, []);
  };

  return uniqBy(
    [
      ...getNodesFromGraph(upstreamGraph),
      ...getNodesFromGraph(baseGraph, true),
      ...getNodesFromGraph(downstreamGraph),
    ],
    "id"
  );
};

const generateEdge = (source: string, target: string): Edge => ({
  id: `${source}-${target}`,
  source,
  target,
  type: "smoothstep",
  style: getEdgeStyle(),
});

export const generateInitialEdges = (
  upstreamGraph: GraphWithInfos,
  baseGraph: GraphWithInfos,
  downstreamGraph: GraphWithInfos
): Edge[] => {
  const baseGraphKey = Object.keys(baseGraph).at(0);
  const downstreamGraphFirstKeys = Object.keys(downstreamGraph);
  const upstreamGraphFirstKeys = Object.keys(upstreamGraph);

  if (!baseGraphKey || Object.keys(baseGraph).length !== 1) {
    throw new Error("Base graph should have a unique element");
  }

  const baseEdges: Edge[] = [
    ...downstreamGraphFirstKeys.map((targetKey) =>
      generateEdge(baseGraphKey, targetKey)
    ),
    ...upstreamGraphFirstKeys.map((sourceKey) =>
      generateEdge(sourceKey, baseGraphKey)
    ),
  ];

  const getEdgesFromGraph = (
    graph: GraphWithInfos,
    type: GraphType.UPSTREAM | GraphType.DOWNSTREAM
  ): Edge[] => {
    return Object.keys(graph).reduce<Edge[]>((edges, key) => {
      const { relatives } = graph[key];

      return [
        ...edges,
        ...Object.keys(relatives).map(
          (relativeKey) =>
            ({
              [GraphType.UPSTREAM]: generateEdge(relativeKey, key),
              [GraphType.DOWNSTREAM]: generateEdge(key, relativeKey),
            }[type])
        ),
        ...getEdgesFromGraph(relatives, type),
      ];
    }, []);
  };

  return uniqBy(
    [
      ...getEdgesFromGraph(upstreamGraph, GraphType.UPSTREAM),
      ...baseEdges,
      ...getEdgesFromGraph(downstreamGraph, GraphType.DOWNSTREAM),
    ],
    "id"
  );
};

export const getLayoutedElements = (
  nodes: Node[],
  edges: Edge[],
  direction = Direction.LR
) => {
  const dagreGraph = new dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
  const isHorizontal = direction === Direction.LR;

  dagreGraph.setGraph({ rankdir: direction });

  nodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: NODE_WIDTH, height: NODE_HEIGHT });
  });

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const newNodes = nodes.map((node) => {
    const nodeWithPosition = dagreGraph.node(node.id);
    const newNode: Node = {
      ...node,
      targetPosition: isHorizontal ? Position.Left : Position.Top,
      sourcePosition: isHorizontal ? Position.Right : Position.Bottom,
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      position: {
        x: nodeWithPosition.x - NODE_WIDTH / 2,
        y: nodeWithPosition.y - NODE_HEIGHT / 2,
      },
    };

    return newNode;
  });

  return { nodes: newNodes, edges };
};

export const GRID_SIZE = 68;

export const generateEngine = (engine: DiagramEngine): DiagramEngine => {
  engine
    .getPortFactories()
    .registerFactory(
      new SimplePortFactory(
        "diamond",
        (config) => new SimplePortModel(PortModelAlignment.LEFT)
      )
    );
  engine
    .getPortFactories()
    .registerFactory(
      new SimplePortFactory(
        "diamond",
        (config) => new SimplePortModel(PortModelAlignment.RIGHT)
      )
    );

  engine.getLabelFactories().registerFactory(new DefaultLabelFactory());
  engine.getLabelFactories().registerFactory(new EditableLabelFactory());
  engine.getLinkFactories().registerFactory(new FlowLinkFactory());
  engine.getNodeFactories().registerFactory(new DatasetNodeFactory());
  engine.getNodeFactories().registerFactory(new ExplorationNodeFactory());

  engine.maxNumberPointsPerLink = 0;

  return engine;
};
