import type { Edge, Node } from "@xyflow/react";
import { useMemo } from "react";
import type { TableTabItem } from "../../../../../../spreadsheet/domain";
import type { GraphWithInfos } from "../domain";
import {
  generateInitialEdges,
  generateInitialNodes,
  getGraphWithInfos,
  getLayoutedElements,
  GraphType,
} from "../domain";

type Props = {
  tabItems: TableTabItem[];
  current: TableTabItem | undefined;
  parentIds: string[];
  childIds: string[];
};

export const useLayoutedElements = ({
  tabItems,
  current,
  parentIds,
  childIds,
}: Props) => {
  const currentId = current?.dataset.id ?? "";

  const baseGraph: GraphWithInfos = useMemo(
    () => getGraphWithInfos(tabItems, [currentId], GraphType.BASE),
    [currentId, tabItems]
  );
  const upstreamGraph: GraphWithInfos = useMemo(
    () => getGraphWithInfos(tabItems, parentIds, GraphType.UPSTREAM),
    [parentIds, tabItems]
  );
  const downstreamGraph: GraphWithInfos = useMemo(
    () => getGraphWithInfos(tabItems, childIds, GraphType.DOWNSTREAM),
    [tabItems, childIds]
  );
  const initialNodes: Node[] = useMemo(
    () => generateInitialNodes(upstreamGraph, baseGraph, downstreamGraph),
    [baseGraph, downstreamGraph, upstreamGraph]
  );
  const initialEdges: Edge[] = useMemo(
    () => generateInitialEdges(upstreamGraph, baseGraph, downstreamGraph),
    [baseGraph, downstreamGraph, upstreamGraph]
  );

  return getLayoutedElements(initialNodes, initialEdges);
};
