import { Form, Input, Select, Space, Switch, type FormInstance } from "antd";
import type { IObject } from "../../../../../../../../interfaces/object";
import { ChartOptionCollapse } from "../../../../../../../chart-options/components/ChartOptionCollapse";
import ChartOptionLine from "../../../../../../../chart-options/components/ChartOptionLine";
import { getObjectColumns } from "../../../../../object/domain";
import type { IRecord } from "../../../../domain";
import { MarkdocFormItem } from "../../../common/markdoc/MarkdocFormItem";
import type { IWidget } from "../../../domain";
import WidgetRelatedListEditor from "../../related-lists/editor/WidgetRelatedListEditor";
import type { IWidgetInlineObjectiveConfig } from "../domain";

interface InlineObjectiveWidgetEditorProps {
  widget: IWidget;
  object: IObject;
  record: IRecord;
  conf: IWidgetInlineObjectiveConfig;
  form: FormInstance;
}

export function InlineObjectiveWidgetEditor(
  props: InlineObjectiveWidgetEditorProps
) {
  const { widget, object, record, conf, form } = props;

  const columns = getObjectColumns(object);

  return (
    <>
      <ChartOptionCollapse dark title="Header">
        <Space size="middle" style={{ width: "100%" }} direction="vertical">
          <ChartOptionLine
            items={[
              {
                content: "Title",
                flex: 1,
              },
              {
                content: (
                  <>
                    <MarkdocFormItem
                      fieldName={["config", "title"]}
                      columns={columns}
                    />
                  </>
                ),
                flex: `0 auto`,
              },
            ]}
          />
          <ChartOptionLine
            items={[
              {
                content: "Show count",
                flex: 1,
              },
              {
                content: (
                  <Form.Item
                    valuePropName="checked"
                    noStyle
                    name={["config", "count", "show"]}
                  >
                    <Switch size="small" />
                  </Form.Item>
                ),
                flex: 0,
              },
            ]}
          />
          <Form.Item noStyle shouldUpdate={true}>
            {() => {
              const val = form.getFieldValue(["config", "count", "show"]);
              if (!val) {
                return;
              }
              return (
                <ChartOptionLine
                  items={[
                    {
                      content: "Label",
                      flex: 1,
                    },
                    {
                      content: (
                        <Form.Item noStyle name={["config", "count", "label"]}>
                          <Input size="small" />
                        </Form.Item>
                      ),
                      flex: 1,
                    },
                  ]}
                />
              );
            }}
          </Form.Item>
          <ChartOptionLine
            items={[
              {
                content: "Show objective",
                flex: 1,
              },
              {
                content: (
                  <Form.Item noStyle name={["config", "objective", "show"]}>
                    <Switch size="small" />
                  </Form.Item>
                ),
                flex: 0,
              },
            ]}
          />
          <Form.Item noStyle shouldUpdate={true}>
            {() => {
              const val = form.getFieldValue(["config", "objective", "show"]);
              if (!val) {
                return;
              }
              return (
                <ChartOptionLine
                  items={[
                    {
                      content: "Column",
                      flex: 1,
                    },
                    {
                      content: (
                        <Form.Item
                          noStyle
                          name={["config", "objective", "value"]}
                        >
                          <Select
                            showSearch={true}
                            optionFilterProp="children"
                            popupMatchSelectWidth={false}
                            style={{ width: "100%" }}
                          >
                            {columns
                              .filter((c) => {
                                if (
                                  c.type === "property" &&
                                  c.data.domain === "NUMERIC"
                                ) {
                                  return true;
                                } else if (c.type === "metric") {
                                  return true;
                                }
                              })
                              .map((c) => {
                                return (
                                  <Select.Option
                                    key={c.data.key}
                                    value={c.data.key}
                                  >
                                    {c.data.label}
                                  </Select.Option>
                                );
                              })}
                          </Select>
                        </Form.Item>
                      ),
                      flex: 1,
                    },
                  ]}
                />
              );
            }}
          </Form.Item>
        </Space>
      </ChartOptionCollapse>
      <WidgetRelatedListEditor
        widget={widget}
        object={object}
        record={record}
        conf={conf}
        form={form}
      />
    </>
  );
}
